import gql from 'graphql-tag'
import { apolloProvider } from '../vue-apollo'
import _ from 'lodash'

export default {
  async install (Vue, options) {
    const textArrayPromise = apolloProvider.defaultClient.query({ query: gql`query texts {texts{id, key, value}}` })

    Vue.mixin({
      data: () => ({
        fetchTexts: {
        }
      }),
      async created () {
        if (this.$options.fetchTexts) {
          const { data: { texts: textArray } } = await textArrayPromise
          for (const { key, value } of textArray) {
            this.$set(this.fetchTexts, key, value)

            const keyPath = _.toPath(key)
            if (keyPath.length <= 1) continue

            const fetchTextClone = _.clone(this.fetchTexts)
            _.set(fetchTextClone, keyPath, value)
            this.fetchTexts = fetchTextClone
          }
        }
      },
      methods: {
        $t (key) {
          const renderTemplate = _.template(this.fetchTexts?.[key] || '')
          return renderTemplate(this.fetchTexts || {})
        }
      }
    })
  }
}

<template>
  <div>
    <v-card-title>
      {{ $t('registration.title') }}
    </v-card-title>
    <v-card-subtitle v-if="currentServices && currentServices.length === 1">
      {{ $t('registration.subtitle') }} {{ dateTime(currentServices[0].serviceStartsAt) }}
    </v-card-subtitle>
    <v-card-text>
      <v-form ref="form">
        <v-simple-table v-if="currentServices && currentServices.length > 1">
          <thead>
            <tr>
              <th class="text-left">
                {{ $t('initial.labelService') }}
              </th>
              <th />
              <th class="text-left">
                {{ $t('initial.labelFreeSeats') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in tableRows"
              :key="index"
            >
              <td>
                <v-checkbox
                  v-model="form.serviceIds"
                  :value="item.id"
                  :disabled="item.disabled"
                  :rules="[rules.atLeastOneService]"
                >
                  <template
                    #label
                  >
                    <span class="body-2">{{ item.date }}</span>
                  </template>
                </v-checkbox>
              </td>
              <td>{{ item.name }}</td>
              <td>{{ item.content }}</td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-row no-gutters>
          <v-col cols="6">
            <v-text-field
              v-model="form.name"
              label="Vorname *"
              name="name"
              prepend-icon="mdi-account"
              type="text"
              autocomplete="given-name"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.surname"
              class="ml-2"
              label="Name *"
              name="surname"
              type="text"
              autocomplete="family-name"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>

        <v-text-field
          v-model="form.street"
          label="Straße"
          name="street"
          prepend-icon="mdi-map-marker"
          type="text"
          autocomplete="street-address"
          :rules="[]"
        />
        <v-row no-gutters>
          <v-col cols="4">
            <v-text-field
              v-model="form.zip"
              label="PLZ"
              name="zip"
              prepend-icon="mdi-city"
              type="text"
              autocomplete="postal-code"
              :rules="[rules.onlyNumbers]"
            />
          </v-col>
          <v-col cols="8">
            <v-text-field
              v-model="form.city"
              class="ml-2"
              label="Stadt"
              name="city"
              type="text"
              autocomplete="address-level2"
              :rules="[]"
            />
          </v-col>
        </v-row>

        <v-text-field
          v-model="form.phone"
          label="Telefonnummer"
          prepend-icon="mdi-phone"
          name="phone"
          type="tel"
          autocomplete="tel"
          :rules="[rules.noLetters, rules.phone]"
        />

        <v-text-field
          v-model="form.email"
          label="E-Mail Adresse"
          prepend-icon="mdi-email"
          name="email"
          type="email"
          autocomplete="email"
          :rules="[rules.email]"
        />

        <v-radio-group
          v-for="(radioGroup, key) in dynamicFieldsRadioGroups"
          :key="key"
          v-model="form.additionalData[key]"
          :rules="radioGroup.required ? [rules.required] : []"
        >
          <template v-slot:label>
            <div v-if="radioGroup.label">
              {{ radioGroup.label + (radioGroup.required ? ' *' : '') }}
            </div>
          </template>
          <v-radio
            v-for="radioValue in radioGroup.values"
            :key="radioValue"
            :label="radioValue"
            :value="radioValue"
          />
        </v-radio-group>

        <template v-for="field in dynamicFieldsWithoutRadio">
          <v-checkbox
            v-if="field.type === 'checkbox'"
            :key="field.id"
            v-model="form.additionalData[field.key]"
            :name="field.key"
            :label="field.label + (field.required ? ' *' : '')"
            :rules="field.required ? [rules.required] : []"
          />

          <v-text-field
            v-else
            :key="field.id"
            v-model="form.additionalData[field.key]"
            :label="field.label + (field.required ? ' *' : '')"
            prepend-icon="mdi-information-outline"
            :name="field.key"
            :type="field.type || 'text'"
            :rules="field.required ? [rules.required] : []"
          />
        </template>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        @click="$root.changeView('Initial')"
      >
        <v-icon left>
          mdi-arrow-left
        </v-icon>
        {{ $t('registration.buttonBack') }}
      </v-btn>
      <v-spacer />
      <v-btn
        color="primary"
        @click="onRegister"
      >
        {{ $t('registration.buttonRegister') }}
        <v-icon right>
          mdi-content-save-outline
        </v-icon>
      </v-btn>
    </v-card-actions>
    <v-snackbar
      v-model="isSnackbarReuseActive"
      :timeout="-1"
    >
      Möchtest du deine Angaben vom letzten Mal wiederverwenden?
      <template #action="{ attrs }">
        <v-btn
          v-bind="attrs"
          @click="reuseDataFromLastTime"
        >
          <v-icon left>
            mdi-folder-outline
          </v-icon>
          Daten laden
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackbarValidation"
    >
      {{ $t('registration.validationError') }}
      <template #action>
        <v-btn
          color="info"
          text
          @click="snackbarValidation = false"
        >
          OK
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import delay from 'timeout-as-promise'
import emailValidator from 'email-validator'
import dateTimeMixin from '@/mixins/dateTimeMixin'
import phone from 'phone'
import _ from 'lodash'
import sharedStart from '@/etc/sharedStart'

const normalizePhone = v => phone(v, 'DEU', true)?.[0] || phone(v, '', true)?.[0]

const initialFormData = {
  name: '',
  surname: '',
  street: '',
  zip: '',
  city: '',
  phone: '',
  email: ''
}

export default {

  fetchTexts: true,

  mixins: [dateTimeMixin],

  props: {
    currentServices: {
      type: Array,
      default: null
    },
    dynamicFields: {
      type: Array,
      default: () => []
    }
  },

  data () {
    const additionalData = {}
    _.each(this.dynamicFields, ({ key }) => { additionalData[key] = '' })
    return {
      snackbarValidation: false,
      checkbox: false,
      form: {
        ...initialFormData,
        serviceIds: this.currentServices?.length === 1
          ? [this.currentServices[0].id]
          : [],
        additionalData
      }
    }
  },

  computed: {
    dynamicFieldsRadioGroups () {
      const radioItems = _.filter(this.dynamicFields, { type: 'radio' })
      const radioGroups = _.reduce(radioItems, (radioGroups = {}, radioItem) => {
        const radioGroup = radioGroups[radioItem.key] || {}
        radioGroup.values = radioGroup.values || []
        radioGroup.values.push(radioItem.label)
        radioGroup.required = radioGroup.required || radioItem.required
        radioGroups[radioItem.key] = radioGroup
        return radioGroups
      }, {})
      const radioGroupsWithLabel = _.mapValues(radioGroups, (radioGroup) => {
        const label = _.trim(sharedStart(radioGroup.values))
        return {
          ...radioGroup,
          label,
          values: _.map(radioGroup.values, (value = '') => _.trim(value.replace(label, '')))
        }
      })
      return radioGroupsWithLabel
    },
    dynamicFieldsWithoutRadio () {
      return _.reject(this.dynamicFields, { type: 'radio' })
    },
    isSnackbarReuseActive () {
      // if a user did an input, don't show the snackbar
      const hasChangedFromInitialState = _
        .chain(initialFormData)
        .map((value, key) => this.form[key] !== value)
        .some()
        .value()
      if (hasChangedFromInitialState) {
        return false
      }

      // if we have saved data, show the snackbar
      return !!localStorage.getItem('reuseData')
    },
    rules: () => ({
      required: v => !!v || 'Diese Angabe ist erforderlich',
      email: v => !v || emailValidator.validate(v) || 'Dies ist keine gütlige E-Mail Adresse',
      phone: v => !v || !!normalizePhone(v) || 'Dies ist keine gültige Telefonnummer',
      noLetters: v => !v || !/[a-zA-Z]/gm.test(v) || 'Hier dürfen keine Buchstaben enthalten sein',
      onlyNumbers: v => !v || /^[0-9]*$/.test(v) || 'Hier dürfen nur Zahlen eingegeben werden',
      atLeastOneService: v => !!v.length || 'Bitte triff mindestens eine Auswahl'
    }),
    normalizedForm () {
      return {
        ...this.form,
        phone: _.toString(normalizePhone(this.form.phone))
      }
    },
    seatsAvailable () {
      return _.some(this.currentServices, s => Math.max(s.freePlaces, 0))
    },
    tableRows () {
      // if we have only one service, we don't need this
      if (this.currentServices.length === 1) {
        return []
      }

      // collect all used dates
      const self = this
      const usedDates = _.map(this.form.serviceIds, id => ({
        id,
        dateCode: self.dateCode(_.find(self.currentServices, { id }).serviceStartsAt),
        noDateConflict: _.find(self.currentServices, { id }).noDateConflict
      }))

      return _.map(this.currentServices, currentService => {
        const { serviceStartsAt, additionalInfo, id, noDateConflict } = currentService || {}

        // date & name formatting
        const serviceDate = serviceStartsAt ? this.dateTime(serviceStartsAt) : ''
        const serviceInfo = additionalInfo || ''
        const dateCode = this.dateCode(serviceStartsAt)

        // make service disabled if the date is already in use
        const disabled =
          !noDateConflict &&
          !!_.find(usedDates, ({ dateCode: d, id: i, noDateConflict }) =>
            dateCode === d && id !== i && !noDateConflict
          )

        return {
          date: serviceDate,
          name: serviceInfo,
          content: Math.max(currentService?.freePlaces, 0) || 0,
          id,
          disabled
        }
      })
    }
  },

  watch: {
    seatsAvailable: {
      handler: 'checkZeroSeats'
    }
  },

  methods: {
    reuseDataFromLastTime () {
      const reuseData = JSON.parse(localStorage.getItem('reuseData'))
      this.form = { ...this.form, ...reuseData }
    },
    async onRegister () {
      // check if valid
      if (!this.$refs.form.validate()) {
        this.snackbarValidation = true
        await delay(5000)
        this.snackbarValidation = false
        return
      }

      // save the data locally for next time reuse
      const { name, surname, street, zip, city, phone, email, additionalData } = this.form
      localStorage.setItem('reuseData', JSON.stringify({ name, surname, street, zip, city, phone, email, additionalData }))

      // send data to backend
      await this.$root.createVisitor(this.normalizedForm)

      // go to next view
      this.$root.changeView('Finish')
    },

    checkZeroSeats (seatsAvailable) {
      if (!seatsAvailable) {
        this.$root.changeView('Initial')
      }
    }
  }
}
</script>

<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <slot v-bind=" { on, attrs } " />
      </template>

      <v-card>
        <v-card-title class="text-h5 primary white--text">
          {{ $t('popup.title') || 'Bitte beachte' }}
        </v-card-title>

        <v-card-text>
          <vue-simple-markdown
            :style="{marginTop: '1rem'}"
            :source="markdownSource"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="dialog = false; $root.changeView('Registration')"
          >
            {{ $t('popup.okButton') || 'OK' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  fetchTexts: true,
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    markdownSource () {
      return this.$t('popup.text')
    }
  }
}
</script>

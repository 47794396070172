<template>
  <!-- eslint-disable vue/no-v-html -->
  <div>
    <v-card-title>
      {{ $t('initial.title') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('initial.subtitle') }}
    </v-card-subtitle>
    <v-card-text>
      <v-simple-table v-if="seatsAvailable">
        <thead>
          <tr>
            <th class="text-left">
              {{ $t('initial.labelService') }}
            </th>
            <th />
            <th class="text-left">
              {{ $t('initial.labelFreeSeats') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in tableRows"
            :key="index"
          >
            <td>{{ item.date }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.content }}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <template v-if="seatsAvailable">
        <p
          class="mt-4"
          v-html="$t('initial.paragraph1')"
        />
        <p v-html="$t('initial.paragraph2')" />
        <p v-html="$t('initial.paragraph3')" />
      </template>
      <p
        v-else
        class="mt-4"
      >
        {{ $t('initial.noSeats') }}
      </p>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        v-if="!$t('popup.text')"
        color="primary"
        :disabled="!seatsAvailable"
        @click="$root.changeView('Registration')"
      >
        <v-icon right>
          mdi-arrow-right
        </v-icon>
        {{ $t('initial.buttonGo') }}
      </v-btn>
      <Dialog
        v-else
        v-slot="{ on, attrs }"
      >
        <v-btn
          color="primary"
          :disabled="!seatsAvailable"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon right>
            mdi-arrow-right
          </v-icon>
          {{ $t('initial.buttonGo') }}
        </v-btn>
      </Dialog>
    </v-card-actions>
  </div>
</template>

<script>
import dateTimeMixin from '@/mixins/dateTimeMixin'
import _ from 'lodash'
import Dialog from '@/components/Dialog'

export default {

  fetchTexts: true,

  components: {
    Dialog
  },

  mixins: [dateTimeMixin],

  props: {
    currentServices: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
    }
  },

  computed: {
    tableRows () {
      return _.map(this.currentServices, currentService => {
        const { serviceStartsAt, additionalInfo } = currentService || {}
        const serviceDate = serviceStartsAt ? this.dateTime(serviceStartsAt) : ''
        const serviceInfo = additionalInfo || ''
        return {
          date: serviceDate,
          name: serviceInfo,
          content: Math.max(currentService?.freePlaces, 0) || 0
        }
      })
    },
    seatsAvailable () {
      return _.some(this.currentServices, s => Math.max(s.freePlaces, 0))
    }
  }

}
</script>

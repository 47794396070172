<template>
  <div>
    <v-card-title>
      {{ $t('finish.title') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('finish.subtitle') }}
    </v-card-subtitle>
    <v-card-text>
      <p>
        {{ $t('finish.paragraph') }}
      </p>
      <v-simple-table>
        <tbody>
          <tr>
            <td><span class="text-caption">{{ $t('finish.labelName') }}</span></td>
            <td>{{ `${name} ${surname}` }}</td>
          </tr>
          <tr v-if="street || zip || city">
            <td><span class="text-caption">{{ $t('finish.labelAddress') }}</span></td>
            <td>{{ `${street}, ${zip} ${city}` }}</td>
          </tr>
          <tr v-if="phone">
            <td><span class="text-caption">{{ $t('finish.labelPhone') }}</span></td>
            <td>{{ phone }}</td>
          </tr>
          <tr v-if="email">
            <td><span class="text-caption">{{ $t('finish.labelEmail') }}</span></td>
            <td>{{ email }}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-simple-table class="my-5">
        <thead>
          <tr>
            <th class="text-left">
              {{ $t('finish.labelId') }}
            </th>
            <th class="text-left">
              {{ $t('finish.labelService') }}
            </th>
            <th class="text-left">
              {{ $t('finish.labelTime') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in tableRows"
            :key="index"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.time }}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <p>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <strong v-html="$t('finish.hint')" />
      </p>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="primary"
        @click="$root.changeView('Initial')"
      >
        <v-icon left>
          mdi-replay
        </v-icon>
        {{ $t('finish.buttonRestart') }}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import dateTimeMixin from '@/mixins/dateTimeMixin'
import _ from 'lodash'

export default {

  fetchTexts: true,

  mixins: [dateTimeMixin],

  props: {
    currentServices: {
      type: Array,
      default: null
    },
    visitors: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
    }
  },

  computed: {
    name () {
      return this.visitors?.[0]?.name
    },
    surname () {
      return this.visitors?.[0]?.surname
    },
    street () {
      return this.visitors?.[0]?.street
    },
    zip () {
      return this.visitors?.[0]?.zip
    },
    city () {
      return this.visitors?.[0]?.city
    },
    phone () {
      return this.visitors?.[0]?.phone
    },
    email () {
      return this.visitors?.[0]?.email
    },
    tableRows () {
      return _.map(this.visitors, ({ id, service: { serviceStartsAt, additionalInfo } }) => ({
        id,
        name: (serviceStartsAt && this.date(serviceStartsAt)) + ((additionalInfo && ` (${additionalInfo})`) || ''),
        time: serviceStartsAt && this.time(serviceStartsAt)
      }))
    }
  }
}
</script>

import moment from 'moment'

moment.locale('de')

export default {
  methods: {
    dateTime (dateTime) {
      return moment(dateTime).format('DD.MM.YYYY [um] HH:mm [Uhr]')
    },
    date (dateTime) {
      return moment(dateTime).format('DD.MM.YYYY')
    },
    time (dateTime) {
      return moment(dateTime).format('HH:mm [Uhr]')
    },
    dateCode (dateTime) {
      return moment(dateTime).format('YYYY-MM-DD')
    }
  }
}

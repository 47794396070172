import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { apolloProvider } from './vue-apollo'
import fetchText from './plugins/fetchText'
import VueSimpleMarkdown from 'vue-simple-markdown'
import 'vue-simple-markdown/dist/vue-simple-markdown.css'

Vue.use(fetchText)
Vue.use(VueSimpleMarkdown)

Vue.config.productionTip = false

window.Vue = new Vue({
  vuetify,
  apolloProvider,
  methods: {
    changeView (newView) {
      this.$children[0].changeView(newView)
    },
    async createVisitor (form) {
      return await this.$children[0].createVisitor(form)
    }
  },
  render: h => h(App)
}).$mount('#app')

<template>
  <v-app id="inspire">
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="12"
            md="12"
            class="pb-16"
            style="max-width: 35rem;"
          >
            <v-card
              ref="card"
              class="elevation-12 mb-10"
            >
              <v-toolbar
                color="primary"
                dark
                flat
              >
                <v-toolbar-title>{{ $t('appName') }}</v-toolbar-title>
                <v-spacer />
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-badge
                      right
                      :content="freePlaces"
                      color="secondary"
                      overlap
                    >
                      <v-btn
                        icon
                        large
                        v-on="on"
                      >
                        <v-icon>mdi-seat</v-icon>
                      </v-btn>
                    </v-badge>
                  </template>
                  <span>{{ $t('freePlacePre') }} {{ freePlaces }} {{ $t('freePlacePost') }}</span>
                </v-tooltip>
              </v-toolbar>
              <v-fade-transition leave-absolute>
                <div
                  v-if="isLoading"
                  class="d-flex py-5"
                >
                  <v-spacer />
                  <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                  />
                  <v-spacer />
                </div>
                <component
                  :is="activeView"
                  v-else
                  :current-services="sortedServices"
                  :visitors="visitors"
                  :dynamic-fields="dynamicFields"
                />
              </v-fade-transition>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Initial from './views/Initial'
import Registration from './views/Registration'
import Finish from './views/Finish'
import _ from 'lodash'
import smoothReflow from 'vue-smooth-reflow'
import currentServices from '@/gql/queries/currentServices.gql'
import createVisitor from '@/gql/mutations/createVisitor.gql'
import dynamicFields from '@/gql/queries/dynamicFields.gql'

const availableViews = {
  Initial,
  Registration,
  Finish
}

export default {
  name: 'App',

  fetchTexts: true,

  components: {
    ...availableViews
  },

  mixins: [smoothReflow],

  data: () => ({
    activeView: 'Initial',

    createVisitorIsLoading: false,
    visitors: null
  }),

  computed: {
    isLoading () {
      return this.$apollo.loading || this.createVisitorIsLoading
    },
    freePlaces () {
      return Math.max(_.sum(_.map(this.currentServices, s => s.freePlaces)), 0) || '0'
    },
    sortedServices () {
      return _.sortBy(this.currentServices, ['serviceStartsAt'])
    }
  },

  mounted () {
    this.$smoothReflow({
      el: this.$refs.card.$el
    })
  },

  methods: {
    changeView (newView) {
      // check if view exists
      if (!_.includes(_.keys(availableViews), newView)) {
        throw new Error(`the view '${newView}' is not available.`)
      }

      // change to new view
      this.activeView = newView
    },
    async createVisitor (form) {
      this.createVisitorIsLoading = true
      let result
      try {
        result = await this.$apollo.mutate({
          mutation: createVisitor,
          variables: {
            ...form
          }
        })
      } catch (error) {
        this.createVisitorIsLoading = false
        throw error
      }
      this.createVisitorIsLoading = false
      this.visitors = result?.data?.createVisitor
      return result
    }
  },

  apollo: {
    currentServices: {
      query: currentServices,
      pollInterval: 10000
    },
    dynamicFields: {
      query: dynamicFields
    }
  }
}
</script>

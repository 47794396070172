
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"initial"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Service"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"serviceStartsAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"freePlaces"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"additionalInfo"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":79}};
    doc.loc.source = {"body":"fragment initial on Service {\n  serviceStartsAt\n  freePlaces\n  additionalInfo\n}","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
